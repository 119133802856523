<template>
    <div class="container" :loading="loading">
        <div class="row pt-5" style="justify-content:center" v-if="!editMode">
            <h6>Open Online Store:</h6>
        </div>
        <div class="row no-gutters" v-if="!editMode">
            <div class="col-8 h4 font-weight-normal pt-2 pb-2 p-sm-0 pb-sm-3 elipssis center" style="justify-content: center; text-align: center">
                <a :href="store.publicUrl" target="_blank" class="center"><h5 class="center" style="justify-content: center; color: #9A3324">{{storeEdit.title}}</h5></a>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col image-cover">
                <el-image style="border-radius:30px" :src="store.imageSrc" v-if="!editMode">
                    <template #error>
                        <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>
                <div class="img-overlay mt-lg-5">
                    <el-image :src="store.eventImageSrc">
                        <template #error>
                            <div class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </template>
                    </el-image>
                </div>
                <ImageUploader @crop="setCropData" @image-selected="setSelectedImage" v-if="editMode" :image="store.imageSrc"></ImageUploader>
            </div>
        </div>
        <div class="row pt-2 pb-2" style="justify-content:center; text-align:center">
            <div class="header-text col-10 col-lg-6 mb-1 mb-lg-0 mt-5">
                <div class="row no-gutters">
                    <div class="col-4"><h5 style="color: #983222">Store Open for:</h5></div>
                    <div class="col-8"><h5 style="color: #983222"><EventTimer v-bind:startDate="store.startDate" v-bind:endDate="store.endDate" v-if="store.endDate!=null"></EventTimer></h5></div>
                </div>
            </div>
        </div>
        <div class="row no-gutters mt-lg-5" v-if="editMode">
            <div class="col-12">
                <el-form :model="storeEdit" :rules="storeEditRules" ref="storeEditForm" :label-position="top" hide-required-asterisk="true">
                    <el-form-item label="TITLE" prop="title">
                        <el-input v-model="storeEdit.title" v-fancylabel v-on:keyup.enter="saveChanges"></el-input>
                    </el-form-item>
                    <el-form-item label="DESCRIPTION" prop="description">
                        <el-input v-model="storeEdit.description" v-fancylabel v-on:keyup.enter="saveChanges"></el-input>
                    </el-form-item>
                    <el-form-item label="STORE TARGET AMOUNT" prop="targetamount">
                        <el-input v-model="storeEdit.targetamount" v-fancylabel v-on:keyup.enter="saveChanges"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h6><label class="top-label">Open Store Information</label></h6>
                <div class="title">
                    <div style="overflow: hidden; ">
                        <div class="row">
                            <div class="col-4 font-weight-normal"><h6>Edit Store:</h6></div>
                            <el-button-group class="col-8">
                                <el-button style="width: 130px; background-color: #983222" class="mr-2" type="info" size="small" icon="el-icon-edit-outline" @click="editMode=true" v-if="!editMode" title="Edit Store, this button allows you to make changes to Store title, Description and Store Picture!">Edit Store</el-button>
                                <el-button style="width: 130px; background-color: red" class="mr-2" type="info" size="small" icon="el-icon-check" :loading="loading" @click="saveChanges" v-if="editMode" title="Save Changes to Store!">Save Changes</el-button>
                            </el-button-group>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-normal"><h6>Store Title:</h6></div>
                            <div class="col-8 text-left"><h6>{{storeEdit.title}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-normal"><h6>Store Description:</h6></div>
                            <div class="col-8 text-left"><h6>{{storeEdit.description}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-normal"><h6>Open your Store page:</h6></div>
                            <div class="col-8 text-left">
                                <a :href="store.publicUrl" target="_blank" class="center"><h6 class="center" style="justify-content: center; color: #9A3324">{{storeEdit.title}}</h6></a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-normal"><h6>Copy Link to Online Store:</h6></div>
                            <el-popover placement="top"
                                        :width="650"
                                        auto-close="4000"
                                        :trigger="click"
                                        effect="dark"
                                        content="Copied Online Store to clipboard, you can now paste the link into an Email or SMS message!">
                                <template #reference>
                                    <h6 class="col-7 el-icon-copy-document c-pointer h6" style="color: #9A3324" title="Copy Online Store URL to your clipboard, then you can paste into an email or text message" id="storeCode" :data-copy="store.publicUrl" @click="copyCode"> {{store.publicUrl}}</h6>
                                </template>
                            </el-popover>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-normal"><h6>Fundraiser Start Date:</h6></div>
                            <div class="col-8 text-left"><h6>{{new Date(store.eventStartDate).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-normal"><h6>Fundraiser End Date:</h6></div>
                            <div class="col-8 text-left"><h6>{{new Date(store.eventEndDate).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</h6></div>
                        </div>
                        <div class="row" v-if="hasRunningEvent">
                            <div class="col-4 font-weight-normal"><h6>Fundraiser:</h6></div>
                            <a class="col-8 text-left nav-link c-pointer" style="font-size:16px; color: #9A3324" @click="toEvent"><h6>Go Back to Running Fundraiser Dashboard</h6></a>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-normal"><h6>Share Store:</h6></div>
                            <div class="col-8">
                                <el-button-group>
                                    <a style="background-color:aquamarine" class="el-button el-button--small mr-2" v-bind:href="smsUrl" v-if="isMobile"><i class="fas fa-sms" style='color: white'></i></a>
                                    <el-button v-if="!isMobile" class="mr-1" size="small" type="success" icon="fas fa-sms" @click="invDonatorSMSVisible=true" title="Share your fundraising Store via SMS!"></el-button>
                                    <el-button class="mr-1" type="primary" size="small" icon="fas fa-envelope" @click="invDonatorVisible=true" title="Share your fundraising Store via Email!"></el-button>
                                    <a class="share-facebook el-button el-button--small mr-1" @click="shareOnFacebook2"><i class="fab fa-facebook" title="Share your Fundraising Store on Facebook"></i></a>
                                    <a class="share-twitter el-button el-button--small" @click="shareOnTwitter"><i class="fab fa-twitter" title="Share your Fundraising Store on Twitter"></i></a>
                                </el-button-group>
                            </div>
                        </div>
                        <hr />
                        <div class="statistics">
                            <div class="row">
                                <div class="col-4 font-weight-normal"><h6>Store Fundrainsing Goal:</h6></div>
                                <div class="col-2 text-left"><h6 class="money">{{parseFloat(storeEdit.targetamount).toFixed(2)}}</h6></div>
                            </div>

                            <div class="row">
                                <div class="col-4 font-weight-normal"><h6>Store Net Sales:</h6></div>
                                <div class="col-2 text-left"><h6 class="money">{{totalNetSales}}</h6></div>
                            </div>

                            <div class="row">
                                <div class="col-4 font-weight-normal"><h6>Total Direct Donations (After Fees):</h6></div>
                                <div class="col-8 text-left"><h6 class="money">{{totalDirectDonationsNet}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4 font-weight-normal"><h6>Total Popcorn Earnings (50%):</h6></div>
                                <div class="col-8 text-left"><h6 class="money">{{totalPopcornSales}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4 font-weight-normal"><h6>Total Fundraiser Earnings:</h6></div>
                                <div class="col-8 text-left"><h6 class="">{{totalEarnedProfits}}</h6></div>
                            </div>

                            <!--<div class="row">
        <div class="col-4 font-weight-normal"><h6>Net Sales:</h6></div>
        <div class="col-8 text-left"><h6 class="money">{{parseFloat(store.subTotal).toFixed(2)}}</h6></div>
    </div>
    <div class="row">
        <div class="col-4 font-weight-normal"><h6>Gross Sales:</h6></div>
        <div class="col-8 text-left"><h6 class="money">{{parseFloat(store.total).toFixed(2)}}</h6></div>
    </div>-->

                            <div class="row">
                                <div class="col-4 font-weight-normal"><h6>Total Donations Received:</h6></div>
                                <div class="col-8 text-left"><h6>{{store.donationsCount}}</h6></div>
                            </div>
                            <!--<div class="row">
        <div class="col-4"><h6>Total Earned:</h6></div>
        <div class="col-8 text-left">
            <el-button class="earnings-button"
                       type="primary"
                       size="medium"
                       @click="openSalesBreakdown"
                       title="Click to view fundraiser earnings breakdown">
                💰 Total Profit: {{totalEarnedProfits}}
            </el-button>
        </div>
    </div>-->
                            <div class="row">
                                <div class="col-4 font-weight-normal"><h6>Fundraiser Progress (%):</h6></div>
                                <div class="col-8 font-weight-normal">
                                    <el-progress text-inside="true" stroke-width="20" :percentage="storePercent" status="success" />
                                </div>
                            </div>
                        </div>
                        <el-dialog title="Fundraiser Store Sales Breakdown"
                                   v-model="showSalesBreakdown"
                                   :width="dialogWidth"
                                   :top="dialogTop">

                            <el-button type="primary" @click="downloadCSV">Download CSV</el-button>

                            <el-table :data="safeEventOrderDetails" class="small-font-table" style="width:100%" height="350">
                                <el-table-column prop="createdAt" label="ORDER DATE" width="150" :formatter="formatDate" />
                                <el-table-column prop="number" label="ORDER NUMBER" width="175" />
                                <el-table-column prop="buyerName" label="BUYER NAME" width="150" />
                                <el-table-column prop="seller" label="SELLER NAME" width="150" />
                                <el-table-column label="POPCORN SALES" width="160" :formatter="calculatePopcornAmount" />
                                <el-table-column prop="directDonationAmount" label="DIRECT DONATION SALES" width="150" :formatter="formatCurrency" />
                                <el-table-column prop="directDonationFee" label="PROCESSING FEE" width="150" :formatter="formatCurrency" />
                                <el-table-column label="FEES DEDUCTED" width="150" :formatter="calculateFeesDeducted" />
                                <el-table-column prop="shippingFee" label="SHIPPING FEE" width="150" :formatter="formatCurrency" />
                                <el-table-column prop="subTotal" label="SUBTOTAL" width="150" :formatter="formatCurrency" />
                                <el-table-column prop="total" label="ORDER TOTAL" width="150" :formatter="formatCurrency" />
                                <el-table-column label="ORDER EARNINGS" width="150" :formatter="calculateEarnings" />
                            </el-table>

                            <!-- Summary Section -->
                            <div class="summary-section">
                              
                                <!-- Summary Section Moved Below -->                              

                                <hr />
                                <div class="row">
                                    <div class="col-9"><h6>Total Profit Earned:</h6></div>
                                    <div class="col-3 text-left"><h6 style="color: green">${{ parseFloat(totalEarnedProfits).toFixed(2) }}</h6></div>
                                </div>
                            </div>

                            <template #footer>
                                <el-button @click="showSalesBreakdown = false">Close</el-button>
                            </template>
                        </el-dialog>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <label class="top-label"><h6>STORE SALES BREAKDOWN</h6></label>
                <el-badge :value="store.donationsCount" v-if="parseInt(store.donationsCount)>0" class="count" title="This is the Number of people that have donated to your cause!">
                </el-badge>

                <div class="donators">
                    <div class="row" style="justify-content:center">
                        <el-button size="small" style="background-color: #983222;" type="danger" @click="downloadCSV">Download CSV</el-button>
                        <el-button size="small" style="background-color: #983222;" type="danger" @click="invDonatorVisible=true" title="Invite new Buyers to your fundraising Store through Email, they will recieve and email invite to buy from your store!">INVITE BUYERS TO YOUR STORE</el-button>
                    </div>
                    <hr />

                    <el-table :data="safeEventOrderDetails" size="mini" style="width:100%" height="350">
                        <el-table-column prop="createdAt" label="Order Date" width="150" :formatter="formatDate" />
                        <el-table-column prop="number" label="Order Number" width="175" />
                        <el-table-column prop="buyerName" label="Buyer Name" width="150" />
                        <el-table-column label="Popcorn Sale Earnings"
                                         width="160"
                                         :formatter="calculatePopcornAmount"
                                         :cell-class-name="getCellClass" />
                        <el-table-column prop="directDonationAmount"
                                         label="Direct Donations"
                                         width="150"
                                         :formatter="formatCurrency"
                                         :cell-class-name="getCellClass" />
                        <el-table-column prop="shippingFee"
                                         label="Shipping Fee"
                                         width="150"
                                         :formatter="formatCurrency"
                                         :cell-class-name="getCellClass" />
                        <el-table-column prop="directDonationFee"
                                         label="Processing Fee"
                                         width="165"
                                         :formatter="formatCurrency" />
                        <el-table-column prop="subTotal"
                                         label="Order Subtotal"
                                         width="150"
                                         :formatter="formatCurrency" />
                        <el-table-column prop="total"
                                         label="Order Total"
                                         width="150"
                                         :formatter="formatCurrency" />
                        <el-table-column label="Order Earnings"
                                         width="150"
                                         :formatter="calculateEarnings"
                                         :cell-class-name="getCellClass" />
                    </el-table>

                    <!-- Summary Section -->
                    <hr />

                    <div class="summary-section">
                        <div class="row">
                            <div class="col-9"><h6>Gross Sales (Order Totals):</h6></div>
                            <div class="col-3 text-left"><h6>${{ parseFloat(grossSales).toFixed(2) }}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-9"><h6>Total Net Sales:</h6></div>
                            <div class="col-3 text-left"><h6>${{ totalNetSales ? parseFloat(totalNetSales).toFixed(2) : "0.00" }}</h6></div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-9"><h6>Total Popcorn Earnings (50%):</h6></div>
                            <div class="col-3 text-left"><h6 style="color:green">${{ totalPopcornSales }}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-9"><h6>Total Direct Donations (After Fees):</h6></div>
                            <div class="col-3 text-left"><h6 style="color:green">${{ totalDirectDonationsNet }}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-9"><h6><strong>Total Net Earnings:</strong></h6></div>
                            <div class="col-3 text-left"><h6 style="color:green"><strong>${{ totalNetEarnings }}</strong></h6></div>
                        </div>
                    </div>
                    <hr />
                    <div class="fundraiser-earnings-explainer">
                        <h6>📊 How Fundraiser Earnings Are Calculated:</h6>
                        <ul>
                            <li><strong>Popcorn Sales:</strong> 50% of total popcorn sales go directly to the fundraiser.</li>
                            <li><strong>Direct Donations:</strong> 90% of each donation goes to the fundraiser, with a 10% fee deducted to cover credit card processing, hosting, website maintenance, and operational costs.</li>
                        </ul>
                        <p><strong>Final Formula:</strong></p>
                        <p>
                            <code>Total Earnings = (Popcorn Sales / 2) + (Direct Donations × 90%)</code>
                        </p>
                    </div>
                </div>
            </div>            
        </div>        
    </div>
        
    <el-dialog title="INVITE BUYERS" :width="dialogWidth" :top="dialogTop" v-model="invDonatorVisible">
        <div class="" style="overflow:auto">
            <form novalidate>
                <el-alert v-show="errorMessage.length>0" :title="errorMessage"
                          type="error"
                          effect="dark">
                </el-alert>
                <div class="row no-gutters pb-2">
                    <div class="col-12 text-break">
                        Invite people to your Store via email.  Enter their email address below and they will recieve an email which will include a direct link to your Fundraiser Store.  
                    </div>
                </div>
                <!--<div class="row no-gutters pb-2 h5">
                    <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/Invite_email.jpg" class="img-fluid center" style="max-width:30%; height:auto" />
                </div>-->
                <div class="row no-gutters pb-2 h5">
                    <div class="col-12">
                        EMAIL
                    </div>
                </div>
                <div class="row no-gutters align-items-center pb-2" v-for="donator in invitedDonators" :key="donator.counter">
                    <div class="col-9">
                        <el-input type="text" size="medium" @input="addInvitedDonator" v-model="donator.email"></el-input>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-2" v-if="donator.counter > 0">
                        <el-button style="background-color: #983222" type="danger" icon="el-icon-close" circle @click="removeDonator(donator)"></el-button>
                    </div>
                </div>
            </form>
        </div>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="invDonatorVisible=false">Cancel</el-button>
                <el-button style="background-color: #983222" type="danger" @click="inviteDonators" :loading="loading">INVITE</el-button>
            </span>
        </template>
    </el-dialog>

    <el-dialog title="INVITE BUYERS WITH SMS" :width="dialogWidth" :top="dialogTop" v-model="invDonatorSMSVisible">
        <div class="" style="overflow:auto">
            <form novalidate>
                <el-alert v-show="errorMessage.length>0" :title="errorMessage"
                          type="error"
                          effect="dark">
                </el-alert>
                <div class="col-12 text-break">
                    Invite people to your Store via sms.  Enter their phone number and they will recieve a text (SMS) message which will include a direct link to your Fundraiser Store. 
                </div>
                <div class="row no-gutters pb-2 h5">
                    <div class="col-12">
                        PHONE NUMBER
                    </div>
                </div>
                <div class="row no-gutters align-items-center pb-2" v-for="donator in invitedDonatorsSMS" :key="donator.counter">
                    <div class="col-9">
                        <el-input type="text" size="medium" @input="addInvitedDonatorSMS" v-model="donator.phone"></el-input>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-2" v-if="donator.counter > 0">
                        <el-button style="background-color: #983222" type="danger" icon="el-icon-close" circle @click="removeDonatorSMS(donator)"></el-button>
                    </div>
                </div>
            </form>
        </div>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="invDonatorSMSVisible=false">Cancel</el-button>
                <el-button style="background-color: #983222" type="danger" @click="inviteDonatorsSMS" :loading="loading">INVITE</el-button>
            </span>
        </template>
    </el-dialog>


</template>

<script>

    import axios from "axios"
    import EventTimer from "./EventTimer.vue";
    import { copyToClipboard } from "../assets/js/app.js";
    import ImageUploader from "./ImageUploader"
    import router from "../router";
    import { reactive } from 'vue'
    import { mapGetters } from "vuex"
    import { initFacebookSdk, } from '@/services';
    
    export default {
        name: "OpenStore",
        components: { EventTimer,ImageUploader },
        data() {
            return {
                showSalesBreakdown: false,
                smsUrl:'',
                isMobile:false,
                errorMessage: '',
                invPartVisible: false,
                invDonatorVisible: false,
                invitedDonators: [{ counter: 0, email: '' }],
                invDonatorSMSVisible: false,
                invitedDonatorsSMS: [{ counter: 0, phone: '' }],
                store: {},
                storeorderdetails: {},
                storeEdit: {},
                loading: true,
                editMode: false,
                file: null,
                storeEditRules: {
                    title: [
                        { required: true, message: 'Title is required', trigger: 'change' }
                    ],
                    description: [
                        { required: true, message: 'Description reason is required', trigger: 'change' }
                    ],
                    targetamount: [
                        { required: true, message: 'Store Target Amount is required', trigger: 'change' }
                    ]
                }
            }
        },
        computed: {
            // 🟢 **Total Direct Donations (Net)**
            // Direct donations minus **10% fee (always)**
            totalDirectDonationsNet() {
                if (!this.safeEventOrderDetails || this.safeEventOrderDetails.length === 0) return 0;

                return this.safeEventOrderDetails.reduce((sum, order) => {
                    let directDonation = parseFloat(order.directDonationAmount) || 0;

                    // Deduct 10% fee (always applied)
                    const directDonationFee = directDonation * 0.10;
                    const netDirectDonations = directDonation - directDonationFee;

                    return sum + netDirectDonations;
                }, 0).toFixed(2);
            },
            // 🟢 **Total Popcorn Sales (Fundraiser's 50% Share)**
            // Popcorn sales = (Subtotal - Direct Donations) / 2
            totalPopcornSales() {
                if (!this.safeEventOrderDetails || this.safeEventOrderDetails.length === 0) return 0;

                return this.safeEventOrderDetails.reduce((sum, order) => {
                    const subTotal = parseFloat(order.subTotal) || 0;
                    const directDonationAmount = parseFloat(order.directDonationAmount) || 0;

                    // Extract popcorn earnings (50% of popcorn sales)
                    return sum + ((subTotal - directDonationAmount) / 2);
                }, 0).toFixed(2);
            },
            // 🟢 **Total Net Earnings (Final Fundraiser Profit)**
            // Net Earnings = Popcorn Earnings + Direct Donations (after 10% fee)
            totalNetEarnings() {
                return (parseFloat(this.totalDirectDonationsNet) + parseFloat(this.totalPopcornSales)).toFixed(2);
            },
            // 🟢 **Gross Sales** (Total of all orders)
            grossSales() {
                if (!this.storeorderdetails || !Array.isArray(this.storeorderdetails)) return 0;

                return this.storeorderdetails.reduce((sum, order) => {
                    if (!order || typeof order !== "object") return sum; // Skip invalid orders

                    const orderTotal = parseFloat(order.total) || 0; // Ensure total is a number
                    console.log(`Adding Order Total: ${orderTotal}`); // Debug each order

                    return sum + orderTotal;
                }, 0).toFixed(2);
            },
            // 🟢 **Total Net Sales** (Sum of all order subtotals excluding shipping)
            totalNetSales() {
                if (!this.storeorderdetails || !Array.isArray(this.storeorderdetails)) return 0;

                return this.storeorderdetails.reduce((sum, order) => {
                    if (!order || typeof order !== "object") return sum; // Skip invalid orders

                    const subTotal = parseFloat(order.subTotal) || 0; // Ensure subTotal is a number
                    console.log(`Adding Order Subtotal: ${subTotal}`); // Debug each order

                    return sum + subTotal;
                }, 0).toFixed(2);
            },
            // 🟢 **Total Earned Profits (Store Profits)**
            totalEarnedProfits() {
                if (!this.storeorderdetails || !Array.isArray(this.storeorderdetails) || this.storeorderdetails.length === 0) {
                    return "$0.00"; // Handle empty or invalid data
                }

                // Sum up earnings per order
                const totalEarnings = this.storeorderdetails.reduce((sum, order) => {
                    return sum + this.extractRawEarnings(order); // Extract raw number for calculation
                }, 0);

                // Format as currency
                return new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(totalEarnings);
            },
            safeEventOrderDetails() {
                return Array.isArray(this.storeorderdetails) ? this.storeorderdetails : [];
            },
            donators() {
                let dn = [];
                if (this.store.donators != null) {
                    this.store.donators.split(',').forEach(p => {
                        let items = p.split('_');
                        dn.push({ name: items[0], amount: items[1], storeviewid: items[2], orderdate: items[3] });
                    });
                }
                return dn;
            },
            dialogWidth() {
                if (screen.width <= 576)
                    return "100%";
                else return "60%";
            },
            dialogTop() {
                if (screen.width <= 576)
                    return "0";
                else return "15vh";
            },
            //storePercent() {
            //    // Ensure both values are valid
            //    if (!this.totalEarnedProfits || !this.store || !this.store.targetAmount) return 0;

            //    // Remove "$" and convert to a number
            //    const earned = parseFloat(this.totalEarnedProfits.toString().replace(/[$,]/g, "")) || 0;
            //    const target = parseFloat(this.store.targetAmount) || 1; // Prevent division by zero

            //    console.log(`✔ Calculating Percentage: Earned ${earned}, Target ${target}`);

            //    let percentage = Math.trunc((earned / target) * 100);
            //    return isNaN(percentage) ? 0 : Math.min(percentage, 100);
            //},
            storePercent() {
                // Ensure both values are valid
                if (!this.totalEarnedProfits || !this.store || !this.store.targetAmount) return 0;

                // Remove "$" and convert to a number
                const earned = parseFloat(this.grossSales.toString().replace(/[$,]/g, "")) || 0;
                const target = parseFloat(this.store.targetAmount) || 1; // Prevent division by zero

                console.log(`✔ Calculating Percentage: Earned ${earned}, Target ${target}`);

                let percentage = Math.trunc((earned / target) * 100);
                return isNaN(percentage) ? 0 : Math.min(percentage, 100);
            },
            marks() {
                const marks = reactive({
                    0: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '0%',
                    },
                    20: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '20%',
                    },
                    40: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '40%',
                    },
                    60: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '60%',
                    },
                    80: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '80%',
                    },
                    100: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '100%',
                    },
                })
                return marks;
            },
            ...mapGetters([
                'hasRunningEvent', 'runningEventEndDate'
            ])
        },
        created() {           
            this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            initFacebookSdk();
            axios.get('/api/stores/open/' + this.$route.params.id).then(response => {
                this.loading = false;
                this.store = response.data;
                this.storeEdit.id = this.store.id;
                this.storeEdit.title = this.store.title;
                this.storeEdit.description = this.store.description;
                this.storeEdit.targetamount = this.store.targetAmount;

                this.smsUrl = "sms:?&body=" + encodeURI('Hi, please checkout my new Colorado Jack Giveback fundraising store: ' + '\n' + this.store.title + ' ' + this.store.publicUrl + '\n' + this.store.description);               

                axios.get('/api/stores/StoreOrderDetails/' + this.store.id).then(response => {
                    this.storeorderdetails = response.data;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });

            }).catch((error) => {
                this.loading = false;
            });

           
        },
        methods: {
            // 🟢 **Apply Cell Class Based on Column**
            getCellClass({ column }) {
                const greenColumns = ["Order Earnings", "Popcorn Sales", "Direct Donations"];
                const redColumns = ["Shipping Fee", "Fees Deducted"];

                if (greenColumns.includes(column.label)) {
                    return "green-cell";
                } else if (redColumns.includes(column.label)) {
                    return "red-cell";
                }
                return "";
            },
            // 🟢 **Calculate Total Earnings Per Store**
            calculateEarningsPerStore() {
                if (!this.storeorderdetails || !Array.isArray(this.storeorderdetails) || this.storeorderdetails.length === 0) {
                    return "$0.00";
                }

                // Sum the earnings for all orders in this store
                const totalEarnings = this.storeorderdetails.reduce((sum, order) => {
                    return sum + this.extractRawEarnings(order);
                }, 0);

                return this.formatCurrency(null, null, totalEarnings);
            },
            // 🟢 **Extract Raw Earnings for an Order**
            extractRawEarnings(order) {
                if (!order || typeof order !== "object") return 0;

                const subTotal = parseFloat(order.subTotal) || 0;
                let directDonationAmount = parseFloat(order.directDonationAmount) || 0;
                const directDonationFee = directDonationAmount * 0.10; // Always deduct 10%

                // Calculate Net Direct Donation (after fee deduction)
                const netDirectDonations = directDonationAmount - directDonationFee;

                // Calculate Popcorn Earnings (50% of Popcorn Sales)
                const popcornEarnings = (subTotal - directDonationAmount) / 2;

                return popcornEarnings + netDirectDonations;
            },
            // 🟢 **Open Sales Breakdown Modal**
            openSalesBreakdown() {
                if (!Array.isArray(this.storeorderdetails) || this.storeorderdetails.length === 0) {
                    console.error("No sales data available!");
                    return;
                }
                this.showSalesBreakdown = true;
            },
            // 🟢 **Format Date to MM/DD/YYYY**
            formatDate(_, __, date) {
                if (!date) return "";
                const d = new Date(date);
                return isNaN(d.getTime()) ? "" : d.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
            },
            // 🟢 **Calculate Popcorn Earnings per Order**
            calculatePopcornAmount(row) {
                if (!row || typeof row !== "object") return "$0.00";
                const subTotal = parseFloat(row.subTotal) || 0;
                const directDonationAmount = parseFloat(row.directDonationAmount) || 0;
                return this.formatCurrency(null, null, (subTotal - directDonationAmount) / 2);
            },
            // 🟢 **Calculate Fees Deducted per Order**
            calculateFeesDeducted(row) {
                if (!row || typeof row !== "object") return "$0.00";

                const directDonationAmount = parseFloat(row.directDonationAmount) || 0;
                const directDonationFee = directDonationAmount * 0.10; // Always deduct 10%

                return this.formatCurrency(null, null, directDonationFee);
            },
            // 🟢 **Calculate Total Earnings per Order**
            calculateEarnings(row) {
                if (!row || typeof row !== "object") return "$0.00";

                const subTotal = parseFloat(row.subTotal) || 0;
                let directDonationAmount = parseFloat(row.directDonationAmount) || 0;
                const directDonationFee = directDonationAmount * 0.10; // Always deduct 10%

                // Net Direct Donations
                const netDirectDonations = directDonationAmount - directDonationFee;

                // Popcorn Earnings (50% of Popcorn Sales)
                const popcornEarnings = (subTotal - directDonationAmount) / 2;

                // Final Total Earnings
                const totalEarnings = popcornEarnings + netDirectDonations;

                return this.formatCurrency(null, null, totalEarnings);
            },
            // 🟢 **Format Currency to USD**
            formatCurrency(_, __, value) {
                if (value == null || isNaN(value)) return "$0.00";
                return new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(value);
            },
            downloadCSV() {
                if (!Array.isArray(this.storeorderdetails) || this.storeorderdetails.length === 0) {
                    console.error("No event order details available!");
                    return;
                }

                console.log("Downloading CSV...");

                const headers = [
                    "ORDER DATE", "ORDER NUMBER", "BUYER NAME", "SELLER NAME",
                    "POPCORN SALE EARNING", "DIRECT DONATION SALES", "PROCESSING FEE",
                    "SHIPPING FEE", "SUBTOTAL", "ORDER TOTAL", "ORDER EARNINGS"
                ];

                const csvRows = this.storeorderdetails.map(row => {
                    console.log("Row data:", row); // Debug each row

                    // Ensure numbers are valid and correctly formatted
                    const directDonationAmount = parseFloat(row.directDonationAmount) || 0;
                    const directDonationFee = directDonationAmount * 0.10; // Always 10% deduction

                    return [
                        this.formatDate(null, null, row.createdAt),
                        row.number,
                        row.buyerName,
                        row.seller,
                        this.calculatePopcornAmount(row),
                        this.formatCurrency(null, null, directDonationAmount),
                        this.formatCurrency(null, null, directDonationFee),
                        this.formatCurrency(null, null, row.shippingFee),
                        this.formatCurrency(null, null, row.subTotal),
                        this.formatCurrency(null, null, row.total),
                        this.calculateEarnings(row)
                    ].map(value => {
                        if (value == null) return ""; // Prevent null errors
                        return `"${String(value).replace(/"/g, '""')}"`; // Escape quotes for CSV
                    }).join(",");
                });

                const csvContent = [headers.join(","), ...csvRows].join("\n");

                console.log("Generated CSV:", csvContent); // Debug the final CSV

                // Create and trigger a download link
                const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.setAttribute("download", "fundraiser_sales.csv");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            addInvitedDonator() {
                this.errorMessage = '';
                let last = this.invitedDonators[this.invitedDonators.length - 1];
                if (this.validateEmail(last.email.trim())) {
                    this.invitedDonators.push({ counter: this.invitedDonators.length, email: '' });
                }
            },
            validateEmail(email) {
                var regExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                if (email.trim().match(regExp)) {
                    return true;
                }
                else {
                    return false;
                }
            },
            removeDonator(p) {
                for (var i = 0; i < this.invitedDonators.length; i++) {
                    if (this.invitedDonators[i] === p) {
                        this.invitedDonators.splice(i, 1);
                        break;
                    }
                }
            },
            addInvitedDonatorSMS() {
                this.errorMessage = '';
                let last = this.invitedDonatorsSMS[this.invitedDonatorsSMS.length - 1];
                if (last.phone.trim().length > 9) {
                    //validate phone against regex
                    if (this.validatePhone(last.phone.trim())) {
                        this.invitedDonatorsSMS.push({ counter: this.invitedDonatorsSMS.length, phone: '' });
                    }
                    else {
                        this.errorMessage = 'invalid phone number!';
                    }                    
                }                    
            },
            validatePhone(phone) {
                var regExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
                if (regExp.test(phone)) {
                    return true;
                }
                else {
                    return false;
                }
            },
            removeDonatorSMS(p) {
                for (var i = 0; i < this.invitedDonatorsSMS.length; i++) {
                    if (this.invitedDonatorsSMS[i] === p) {
                        this.invitedDonatorsSMS.splice(i, 1);
                        break;
                    }
                }
            },
            inviteDonators() {
                this.loading = true;
                var emails = [];
                this.invitedDonators.forEach(p => {
                    if (p != '' && this.validateEmail(p.email))
                        emails.push(p.email);
                });

                axios.post('/api/participants/InviteDonator/', { storeId: this.store.id, emails: emails }).then(response => {
                    this.loading = false;
                    this.invDonatorVisible = false;
                    this.$message({
                        message: 'Donator emails have been sent and invited to view your store! - Emails: ' + emails.toString(),
                        type: 'success'
                    });

                }).catch((error) => {
                    this.loading = false;
                });
            },
            inviteDonatorsSMS() {
                this.loading = true;
                var phones = [];
                this.invitedDonatorsSMS.forEach(p => {
                    if (p != '' && this.validatePhone(p.phone))
                        phones.push(p.phone);
                });

                axios.post('/api/participants/SendSMSDonorInvite/', { storeId: this.store.id, phones: phones }).then(response => {
                    this.loading = false;
                    this.invDonatorSMSVisible = false;
                    this.$message({
                        message: 'Donators have been sent an sms message and invited to view your store!',
                        type: 'success'
                    });

                }).catch((error) => {
                    this.loading = false;
                });
            },
            shareOnFacebook3() {
                window.FB.ui(
                    {
                        method: 'feed',
                        name: this.store.title,
                        link: this.store.publicUrl,
                        description: this.store.description,
                        display: 'popup',
                        picture: this.store.imageSrc
                    },
                    // callback
                    function (response) {
                        if (response && !response.error_message) {
                            this.$message({
                                message: 'Successfully shared your public fundraising store on facebook!',
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: 'Error sharing your public fundraising store on facebook! ' + response.error_message,
                                type: 'error'
                            });
                        }
                    }
                );
            },
            shareOnFacebook2() {
                window.FB.ui(
                    {
                        method: 'share_open_graph',
                        action_type: 'og.shares',
                        display: 'popup',
                        action_properties: JSON.stringify({
                            object: {
                                'og:url': this.store.publicUrl,
                                'og:title': this.store.title,
                                'og:description': this.store.description,
                                'og:image:width': '600',
                                'og:image:height': '600',
                                'og:image': this.store.imageSrc
                            }
                        })
                    },
                    // callback
                    function (response) {
                        if (response && !response.error_message) {
                            this.$message({
                                message: 'Successfully shared your public fundraising store on facebook!',
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: 'Error sharing your public fundraising store on facebook! ' + response.error_message,
                                type: 'error'
                            });
                        }
                    }
                );
            },
            shareOnFacebook() {
                window.FB.ui(
                    {
                        method: 'share',
                        href: this.store.publicUrl,
                    },
                    // callback
                    function (response) {
                        if (response && !response.error_message) {
                            this.$message({
                                message: 'Successfully shared your public fundraising store on facebook!',
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: 'Error sharing your public fundraising store on facebook! ' + response.error_message,
                                type: 'error'
                            });
                        }
                    }
                );
            },
            sendOnFacebook() {
                window.FB.ui(
                    {
                        method: 'send',
                        link: this.store.publicUrl,
                    },
                    // callback
                    function (response) {
                        if (response && !response.error_message) {
                            this.$message({
                                message: 'Successfully shared your public fundraising store on facebook!',
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: 'Error sharing your public fundraising store on facebook! ' + response.error_message,
                                type: 'error'
                            });
                        }
                    }
                );
            },
            //sendSMS() {
            //    this.smsUrl = "sms:?& body=" + this.store.publicUrl
            //},
            shareOnTwitter() {
                window.open("https://twitter.com/share?url=" + this.store.publicUrl, "_blank");
            },
            copyCode() {
                copyToClipboard("storeCode");
            },
            setCropData(data) {
                this.storeEdit.imageCropData = data;
            },
            setSelectedImage(file) {
                this.file = file;
            },
            saveChanges() {
                this.$refs["storeEditForm"].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        var formData = new FormData();
                        if (this.file != null) {
                            formData.append("file", this.file.raw);
                            for (const [key, value] of Object.entries(this.storeEdit.imageCropData)) {
                                formData.append("imageCropData." + key, value);
                            }
                        }

                        for (const [key, value] of Object.entries(this.storeEdit)) {
                            if (key === "imageCropData")
                                continue;
                            formData.append(key, value);
                        }
                        
                        axios.put('/api/stores', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(response => {
                            this.loading = false;
                            this.editMode = false;
                            this.store.imageSrc = response.data;
                            this.$message({
                                message: 'Changes saved to the store.',
                                type: 'success'
                            });
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });         
            },
            toEvent() {
                router.push("/event/running");
            },
        }
    }
</script>

<style scoped>

    .fundraiser-earnings-explainer {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 15px;
        background-color: #f9f9f9;
    }

    .fundraiser-earnings-explainer ul {
        padding-left: 20px;
    }

    .fundraiser-earnings-explainer code {
        background: #eee;
        padding: 3px 6px;
        border-radius: 5px;
    }

    .fundraiser-earnings-explainer ul {
        list-style-type: disc; /* Ensures bullets are shown */
        margin-left: 20px; /* Adds left indentation */
        padding-left: 20px;
    }

    .fundraiser-earnings-explainer li {
        display: list-item; /* Ensures each <li> behaves as a block */
        margin-bottom: 5px; /* Adds spacing between items */
    }



    .green-cell {
        color: green !important;
        font-weight: bold;
    }

    .red-cell {
        color: red !important;
        font-weight: bold;
    }

    img {
        height: 300px;
        object-fit: cover;
        border-radius: 4px;
        width: 100%
    }
    .participants, .donators {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        min-height: 250px;
    }
    .top-label {
        position: relative;
        top: 22px;
        background: #fff;
        left: 15px;
        font-weight: normal;
        padding: 0 5px;
    }
    .count {
        top: 25px;
        left: 15px;
    }
    .title {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        /*min-height: 200px;*/
        flex-direction: inherit;
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 370px) {
        h5 {
            font-size: 1.0rem;
        }

        h6 {
            font-size: .80rem;
            font-weight: normal;
        }
        /*1rem = 16px*/
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 544px) {
        h5 {
            font-size: 1.00rem;
        }

        h6 {
            font-size: .90rem;
        }
        /*1rem = 16px*/
    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 768px) {
        h5 {
            font-size: 1.25rem;
        }

        h6 {
            font-size: .95rem;
        }
        /*1rem = 16px*/
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
        h5 {
            font-size: 1.5rem;
        }

        h6 {
            font-size: 1.0rem;
        }
        /*1rem = 16px*/
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        h5 {
            font-size: 1.75rem;
        }

        h6 {
            font-size: 1.25rem;
        }
        /*1rem = 16px*/
    }
</style>