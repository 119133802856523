<template>
    <div class="container" style="margin-top:50px" >
        <div class="word-line-break h4" v-if="openStores.length > 0"><span>OPEN STORES</span></div>
        <template v-if="openStores.length > 0" class="row">
            <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/coloradojackorders.webp" class="background-pic" />
            <div class="col-12 col-sm-6 col-lg-4" v-for="store in openStores" :key="store.id">
                <el-card style="padding-top:30px" @click="openDetail(store)" key="live" v-loading="loading">
                    <div style="height:300px">
                        <el-image :src="store.imageSrc">
                            <template #error>
                                <div class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </template>
                        </el-image>
                    </div>

                    <div style="padding-top: 15px;">
                        <div class="h5 elipssis">{{store.title}}</div>
                        <div class="bottom row mt-3">
                            <div class="text-left col-6">
                                <div>Fundraiser Start Date</div>
                                <div>{{(new Date(store.startDate)).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</div>
                            </div>
                            <div class="text-right col-6 row">
                                <div class="col-4"><el-button type="primary" icon="el-icon-edit" circle></el-button></div>
                                <div class="col-4">
                                    <a class="share-facebook el-button is-circle" @click="shareOnFacebook(store)" circle><i class="fab fa-facebook-f"></i></a>
                                </div>
                                <div class="col-4">
                                    <a class="share-twitter el-button is-circle" @click="shareOnTwitter(store)" circle><i class="fab fa-twitter"></i></a>
                                </div>
                                <!--<div class="col-4"><el-button type="danger" icon="el-icon-delete" circle></el-button></div>-->
                            </div>
                        </div>
                    </div>
                </el-card>
            </div>
        </template>
        <template v-show="openStores.length < 1 && !this.loading">
            <el-card v-show="openStores.length < 1 && !this.loading" style="padding-top:30px;" >
                <div key="example">
                    <div class="row no-gutters; v-uncloak">
                        <img src="https://coloradojackgivebacksa.blob.core.windows.net/exampleimages/ExampleFundraisingEvent_3d.jpg" class="background-pic-example"  />
                    </div>
                    <div class="v-uncloak" style="text-align: center;"><strong>Welcome thanks for the interest in the Colorado Jack Give back fundraiser!  It looks like you don't have any running events at this time.</strong></div>
                    <div class="v-uncloak" style="text-align: center;">
                        <el-button type="danger" style="background-color: #983222; width:50%; padding-bottom: 20px" size="large" @click="createNew">Create a new fundraiser!</el-button>
                    </div>
                </div>
            </el-card>
        </template>

        <div class="word-line-break h4 mt-5" v-if="upcomingStores.length > 0"><span>UPCOMING STORES</span></div>
        <div class="row" v-if="upcomingStores.length > 0">
            <div class="col-12 col-sm-6 col-lg-4" v-for="store in upcomingStores" :key="store.id">
                <el-card style="padding-top:30px" @click="openDetail(store)" v-loading="loading">
                    <div style="height:300px">
                        <el-image :src="store.imageSrc">
                            <template #error>
                                <div class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </template>
                        </el-image>
                    </div>

                    <div style="padding-top: 15px;">
                        <div class="h5 elipssis">{{store.title}}</div>
                        <div class="bottom row mt-3">
                            <div class="text-left col-6">
                                <div>Starting in</div>
                                <div>{{(new Date(store.startDate)).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</div>
                            </div>
                            <div class="text-right col-6 row">
                                <div class="col-4"><el-button type="primary" icon="el-icon-edit" circle></el-button></div>
                                <div class="col-4">
                                    <a class="share-facebook el-button is-circle" @click="shareOnFacebook(store)"><i class="fab fa-facebook-f"></i></a>
                                </div>
                                <div class="col-4">
                                    <a class="share-twitter el-button is-circle" @click="shareOnTwitter(store)"><i class="fab fa-twitter"></i></a>
                                </div>
                                <!--<div class="col-4"><el-button type="danger" icon="el-icon-delete" circle></el-button></div>-->
                            </div>
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
        <div class="word-line-break h4 mt-5" v-if="closedStores.length > 0"><span>CLOSED STORES</span></div>
        <div class="row" v-if="closedStores.length > 0">
            <div class="col-12 col-sm-6 col-lg-4" v-for="store in closedStores" :key="store.id">
                <el-card style="padding-top:30px" @click="openDetail(store)" v-loading="loading">
                    <div style="height:300px">
                        <el-image :src="store.imageSrc">
                            <template #error>
                                <div class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </template>
                        </el-image>
                    </div>

                    <div style="padding-top: 15px;">
                        <div class="h5 elipssis">{{store.title}}</div>
                        <hr />
                        <div class="bottom row mt-3">
                            <div class="text-left col-5">
                                <div>Closed On</div>
                                <div>{{(new Date(store.endDate)).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</div>
                            </div>
                            <!--<div class="col-3">
                                <div>Collected</div>
                                <div>Target</div>
                            </div>
                            <div class="col-4 text-right">
                                <div>${{store.subTotal}}</div>
                                <div>${{store.targetAmount}}</div>
                            </div>-->
                        </div>
                    </div>
                </el-card>
            </div>
        </div>



    </div>
</template>

<script>
    import axios from "axios"
    import router from "../router";
    import { copyToClipboard } from "../assets/js/app.js";
    import { initFacebookSdk, } from '@/services';

    export default {
        name: "ParticipantStores",
        data() {
            return {
                closedStores: [],
                openStores: [],
                upcomingStores: [],
                loading: false
            }
        },
        methods: {
            shareOnFacebook() {
                window.FB.ui(
                    {
                        method: 'share',
                        href: this.openStores[0].publicUrl,
                    },
                    // callback
                    function (response) {
                        if (response && !response.error_message) {
                            this.$message({
                                message: 'Successfully shared your public fundraising store on facebook!',
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: 'Error sharing your public fundraising store on facebook! ' + response.error_message,
                                type: 'error'
                            });
                        }
                    }
                );
            },
            shareOnTwitter(store) {
                window.open("https://twitter.com/share?url=" + store.publicUrl, "_blank");
            },
            openDetail(store) {

                this.loading = true;
                if (store.status === 'Open')
                    router.push('/store/open/' + store.id);
                else
                    router.push('/store/detail/' + store.id);

                this.loading = false;
            },
            copyCode() {
                copyToClipboard("storeCode");
            },
            createNew() {
                router.push('/NewEvent');
            }
        },
        computed: {

        },
        created() {
            this.loading = true;
            axios.get("/api/stores").then(response => {
                this.upcomingStores = response.data.filter(i => i.status === "Draft");
                this.openStores = response.data.filter(e => e.status === "Open");
                this.closedStores = response.data.filter(i => i.status === "Closed");
               

                //const title = this.openStores[0].title;
                //const descr = this.openStores[0].description;
                //const imgsrc = this.openStores[0].imageSrc;
                //const url = this.openStores[0].publicUrl;
                //const titleEl = document.querySelector('head meta[property="og:title"]');
                //const descEl = document.querySelector('head meta[property="og:description"]');
                //const imagEl = document.querySelector('head meta[property="og:image"]');
                //const urlEl = document.querySelector('head meta[property="og:url"]');

                //titleEl.setAttribute('content', title);
                //descEl.setAttribute('content', descr);
                //imagEl.setAttribute('content', imgsrc);
                //urlEl.setAttribute('content', url)

            });
            this.loading = false;
            initFacebookSdk();
        }
    }
</script>

<style scoped>
    .image-slot {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
    .v-cloak {
        display: none;
    }
</style>