<template>    
    <div class="container" style="margin-top:50px">        
        <div class="word-line-break h4" v-if="this.runningEvent"><span>Running Fundraiser</span></div>
        <template v-if="this.runningEvent" class="row">
            <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/coloradojackorders.webp" alt="background image" class="background-pic" />
            <div style="opacity:initial">
                <el-card style="padding-top:30px;" v-if="this.runningEvent" @click="openDetail(runningEvent)" :loading="loading" key="truerunningevent" v-loading="loading">
                    <div class="row">
                        <div class="col-12 col-sm-4 elipssis" style="height:300px">
                            <el-image :src="runningEvent.imageSrc">
                                <template #error>
                                    <div class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </template>
                            </el-image>
                        </div>
                        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
                            <div class="h6">Title:</div>
                            <div class="h4" @click="openDetail(runningEvent)">{{runningEvent.title}}</div>
                            <div class="mt-2 mt-sm-5">
                                <div class="h6">Description:</div>
                                <div class="h5">{{runningEvent.description}}</div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
                            <div class="row no-gutters">
                                <div class="h6">Fundraiser Time Remaining:</div>
                            </div>
                            <div class="row no-gutters">
                                <div class="header-text col">
                                    <EventTimer v-bind:startDate="runningEvent.startDate" v-bind:endDate="runningEvent.endDate" v-if="runningEvent.endDate!=null"></EventTimer>
                                </div>
                            </div>
                            <div class="row no-gutters mb-5 pb-5 mb-sm-0 pb-sm-0" style="margin-top: 32px;">
                                <div class="row no-gutters statistics">
                                    <div class="col-7">Number of Participants:</div>
                                    <div class="col-5">{{runningEvent.participantsCount}}</div>
                                    <div class="col-7">Number of Buyers:</div>
                                    <div class="col-5">{{runningEvent.donatorsCount}}</div>
                                    <!--<div class="col-7">Collected:</div>
                                    <div class="col-5">${{runningEvent.allStoresSubTotal}}</div>-->
                                    <div class="col-7">Fundraiser Target:</div>
                                    <div class="col-5">${{runningEvent.targetAmount}}</div>
                                </div>
                            </div>
                            <div class="" style="position: absolute; right: 15px; bottom: 0;">
                                <el-button type="danger" @click="openDetail(runningEvent)" style="background-color: #983222">DETAILS</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>
            </div>
        </template>
        <template v-show="!this.runningEvent && !this.loading" >
            <el-card v-show="!this.runningEvent && !this.loading" style="padding-top:30px;" :loading="loading" key="norunningevent">
                <div class="row no-gutters; v-uncloak">
                    <img src="https://coloradojackgivebacksa.blob.core.windows.net/exampleimages/ExampleFundraisingEvent_3d.jpg" class="background-pic-example" />
                </div>
                <div class="v-uncloak" style="text-align: center;"><strong>Welcome thanks for the interest in the Colorado Jack Give back fundraiser!  It looks like you don't have any running events at this time.</strong></div>
                <div class="v-uncloak" style="text-align: center;">
                    <el-button type="danger" style="background-color: #983222; width:50%; padding-bottom: 20px" size="large" @click="createNew">Create a new fundraiser!</el-button>
                </div>
            </el-card>
        </template>


        <div class="word-line-break h4 mt-5" v-if="comingEvents.length > 0"><span>UPCOMING FUNDRAISERS</span></div>
        <div class="row" v-if="comingEvents.length > 0">
            <div class="col-12 col-sm-6 col-lg-4" v-for="event in comingEvents" :key="event.id">
                <el-card style="padding-top:30px" @click="openDetail(event)" v-loading="loading">
                    <div style="height:300px">
                        <el-image :src="event.imageSrc" lazy>
                            <template #error>
                                <div class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </template>
                        </el-image>
                    </div>
                    <div style="padding-top: 15px;">
                        <div class="h5 elipssis">{{event.title}}</div>
                        <div class="bottom row mt-3">
                            <div class="text-left col-12">
                                <div>Fundraiser Start Date</div>
                                <div>{{(new Date(event.startDate)).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</div>
                            </div>
                            <!--<div class="text-right col-6 row">
                                <div class="col-4"><el-button type="primary" icon="el-icon-edit" circle></el-button></div>
                                <div class="col-4"><el-button type="primary" icon="el-icon-delete" circle></el-button></div>
                            </div>-->
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
        <div class="word-line-break h4 mt-5" v-if="closedEvents.length > 0"><span>CLOSED FUNDRAISERS</span></div>
        <div class="row" v-if="closedEvents.length > 0">
            <div class="col-12 col-sm-6 col-lg-4" v-for="event in closedEvents" :key="event.id">
                <el-card style="padding-top:30px" @click="openDetail(event)" v-loading="loading">
                    <div style="height:300px">
                        <el-image :src="event.imageSrc" v-if="!editMode" lazy>
                            <template #error>
                                <div class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </template>
                        </el-image>
                    </div>

                    <div style="padding-top: 15px;">
                        <div class="h5 elipssis">{{event.title}}</div>
                        <hr />
                        <div class="bottom row mt-3">
                            <div class="text-left col-5">
                                <div>Closed On</div>
                                <div>{{(new Date(event.endDate)).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</div>
                            </div>
                            <!--<div class="col-3">
                                <div>Collected</div>
                                <div>Target</div>
                            </div>
                            <div class="col-4 text-right">
                                <div>${{event.allStoresSubTotal}}</div>
                                <div>${{event.targetAmount}}</div>
                            </div>-->
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import EventTimer from "./EventTimer.vue";
    import router from "../router";
    import { copyToClipboard } from "../assets/js/app.js";

    export default {
        name: 'OrganizerEvents',
        components: { EventTimer },
        data() {
            return {
                closedEvents: [],
                comingEvents: [],
                runningEvent: null,
                loading: false
            }
        },
        methods: {         
            openDetail(event) {
                if (event.status === 'Running')
                    router.push('/event/running');
                else if(event.status === 'Ended')
                    router.push('/event/ended/' + event.id);
                else
                    router.push('/event/draft/' + event.id);
                   //router.push('/event/detail/' + event.id);
            },
            copyCode() {
                copyToClipboard("eventCode");
            },
            createNew() {
                router.push('/NewEvent');
            }
        },
        computed:{
          
        },
        mounted() {
            this.loading = true;
            axios.get("/api/events").then(response => {
                const runningEvents = response.data.filter(e => e.status === "Running");
                if (runningEvents.length > 0)
                    this.runningEvent = runningEvents[0];
                this.comingEvents = response.data.filter(i => i.status === "Draft");
                this.closedEvents = response.data.filter(i => i.status === "Ended");
            });
            this.loading = false;
           
        },
        created() {
           
        }
    }
</script>


<style scoped>
    .top-label {
        position: relative;
        top: 22px;
        background: #fff;
        left: 15px;
        font-weight: bold;
        padding: 0 5px;
    }
    .v-cloak {
        display: none;
    }
    .v-uncloak{
        display:normal;
    }

    .el-button {
        background-color: #d40d49;
    }
      
</style>
